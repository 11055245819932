import clsx from "clsx";

export function Base({ color = "gray" }: { color?: "gray" | "blue" }) {
  return (
    <div className="mx-auto">
      <div className={clsx("flex", "items-end", "h-6")}>
        <span
          className={clsx(
            "block",
            "w-1",
            "h-4",
            "mx-[3px]",
            color === "blue" && "bg-blue-600",
            color === "gray" && "bg-gray-400",
            "animate-[loading_0.9s_linear_infinite]"
          )}
        />
        <span
          className={clsx(
            "block",
            "w-1",
            "h-4",
            "mx-[3px]",
            color === "blue" && "bg-blue-600",
            color === "gray" && "bg-gray-400",
            "animate-[loading_0.9s_linear_0.3s_infinite]"
          )}
        />
        <span
          className={clsx(
            "block",
            "w-1",
            "h-4",
            "mx-[3px]",
            color === "blue" && "bg-blue-600",
            color === "gray" && "bg-gray-400",
            "animate-[loading_0.9s_linear_0.6s_infinite]"
          )}
        />
      </div>
    </div>
  );
}
