import { useFetchers, useTransition } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Base } from "./Base";

export function Page() {
  const [loading, setLoading] = useState(false);

  const transition = useTransition();
  const fetchers = useFetchers();
  const state = useMemo<"idle" | "loading">(() => {
    const states = [transition.state, ...fetchers.map((f) => f.state)];
    if (states.every((state) => state === "idle")) return "idle";
    return "loading";
  }, [transition, fetchers]);

  useEffect(() => {
    if (state === "loading") setLoading(true);
    if (state === "idle") setLoading(false);
  }, [transition.state]);

  return (
    <div
      className={clsx(
        "fixed",
        "top-0",
        "left-0",
        "right-0",
        "bottom-0",
        "bg-white",
        "bg-opacity-50",
        "flex",
        "items-center",
        "justify-center",
        "z-50",
        loading ? "opacity-100" : "opacity-0",
        loading ? "pointer-events-auto" : "pointer-events-none",
        "transition-opacity",
        "duration-100"
      )}
    >
      <Base color="blue" />
    </div>
  );
}
