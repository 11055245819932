import clsx from "clsx";
import { Base } from "./Base";

export function Absolute() {
  return (
    <div className={clsx("flex", "items-center", "justify-center", "absolute", "inset-0", "bg-white", "bg-opacity-50")}>
      <Base color="blue" />
    </div>
  );
}
